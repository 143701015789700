import React from "react";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <motion.div
      className="bg-gradient-to-r from-gradientStart to-gradientEnd text-white py-20 px-5 text-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5 }}
    >
      <h1 className="text-5xl md:text-7xl font-extrabold mb-6 animate-pulse">
        Monetize Your Apps
      </h1>
      <p className="text-lg md:text-2xl mb-8">
        Maximize your app revenue with Google AdX
      </p>
      <motion.a
        href="#contact"
        className="bg-white text-purple-600 py-3 px-8 rounded-full text-lg font-semibold shadow-lg hover:bg-gray-100 transition-all duration-300"
        whileHover={{ scale: 1.1 }}
      >
        Get Started Today
      </motion.a>
    </motion.div>
  );
};

export default Hero;
