import React from "react";
import { motion } from "framer-motion";

const Features = () => {
  const supportedApps = [
    { title: "Casual Games", icon: "🎮", description: "Monetize with seamless ads for games with light gameplay." },
    { title: "Strategy/Other Games", icon: "♟️", description: "Integrate programmatic ads in engaging games requiring critical thinking." },
    { title: "Utility Apps", icon: "🛠️", description: "Add advertisements to functional apps like tools or utilities." },
    { title: "Calculators", icon: "🧮", description: "Optimize revenue in apps providing calculation functionalities." },
    { title: "Social Media Apps", icon: "💬", description: "Boost monetization for apps with user interactions and engagement." },
  ];

  return (
    <div id="supported-apps" className="py-16 bg-gray-100">
      <h2 className="text-4xl font-bold text-center mb-10">What Apps Are Supported?</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 px-5">
        {supportedApps.map((app, index) => (
          <motion.div
            key={index}
            className="bg-gradient-to-r from-purple-500 to-indigo-600 text-white shadow-lg p-6 rounded-lg hover:scale-105 transition-transform duration-300"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.2 }}
          >
            <div className="text-5xl mb-4">{app.icon}</div>
            <h3 className="text-xl font-bold">{app.title}</h3>
            <p>{app.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Features;