import React from "react";
import { motion } from "framer-motion";

const Monetization = () => {
  return (
    <motion.div
      className="py-16 px-5 bg-gray-100 text-gray-800"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <h2 className="text-4xl font-bold text-center mb-8">
        What is App Monetization?
      </h2>
      <p className="max-w-3xl mx-auto text-lg text-center">
        App monetization using <strong>Google AdX (Google Ad Exchange)</strong> allows app developers and publishers to maximize revenue through programmatic advertising. Google AdX connects publishers with a global network of advertisers in real-time, enabling higher competition and better revenue opportunities.
      </p>
    </motion.div>
  );
};

export default Monetization;
