import React, { useState } from "react";
import { motion } from "framer-motion";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "Do we need any H5 link to generate revenue?",
      answer: "No, you can make revenue directly from your app, without any H5 link.",
    },
    {
      question: "Will I be paid in eCPM or CPM?",
      answer: "You will be paid on eCPM as it is Google AdX.",
    },
    {
      question: "What is the payment term and revenue share?",
      answer: "The payment term is NET 30, and the revenue share is 80-20.",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div id="faq" className="py-16 bg-gradient-to-b from-blue-50 to-gray-100 px-4 sm:px-6 md:px-8">
      <h2 className="text-4xl font-bold text-center mb-10 text-gray-800">
        Frequently Asked Questions
      </h2>
      <div className="max-w-3xl mx-auto space-y-4">
        {faqs.map((faq, index) => (
          <motion.div
            key={index}
            className="bg-white shadow-md rounded-lg overflow-hidden"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: index * 0.2 }}
          >
            <div
              className="flex justify-between items-center p-4 cursor-pointer bg-gradient-to-r from-purple-500 to-indigo-600 text-white"
              onClick={() => toggleFAQ(index)}
            >
              <h3 className="text-lg font-medium">{faq.question}</h3>
              <motion.span
                className="text-2xl"
                animate={{ rotate: activeIndex === index ? 180 : 0 }}
                transition={{ duration: 0.3 }}
              >
                {activeIndex === index ? "-" : "+"}
              </motion.span>
            </div>
            <motion.div
              initial={{ height: 0 }}
              animate={{
                height: activeIndex === index ? "auto" : 0,
                opacity: activeIndex === index ? 1 : 0,
              }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              className="overflow-hidden"
            >
              <div className="p-4 text-gray-700">{faq.answer}</div>
            </motion.div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
