import React from "react";
import { motion } from "framer-motion";

const Steps = () => {
  const steps = [
    { number: 1, title: "Share Your App Store URL" },
    { number: 2, title: "Approval from Google (Same Day)" },
    { number: 3, title: "Integrate the Ad Tags" },
  ];

  return (
    <motion.div
      id="steps"
      className="py-16 bg-gradient-to-b from-purple-500 to-indigo-600 text-white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <h2 className="text-4xl font-bold text-center mb-10">Steps to Get Started</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-5">
        {steps.map((step, index) => (
          <motion.div
            key={index}
            className="flex flex-col items-center bg-white text-gray-800 shadow-lg p-6 rounded-lg"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.2 }}
          >
            <div className="text-3xl font-bold bg-indigo-600 text-white w-14 h-14 flex items-center justify-center rounded-full mb-4">
              {step.number}
            </div>
            <h3 className="text-lg font-medium text-center">{step.title}</h3>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default Steps;
