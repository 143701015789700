import React, { useState } from "react";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    skype: "",
    accountType: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // For loading state

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = "Name is required";
    if (!formData.accountType) newErrors.accountType = "Account type is required";
    if (!formData.email.trim() || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email))
      newErrors.email = "Valid email is required";
    if (!formData.phone.trim() || !/^\d{10}$/.test(formData.phone))
      newErrors.phone = "Valid 10-digit phone number is required";
    if (!formData.skype.trim()) newErrors.skype = "Skype ID is required";
    if (!formData.message.trim()) newErrors.message = "Message is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setLoading(true); // Start loading
      const payload = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        skype: formData.skype,
        account: formData.accountType,
        message: formData.message,
      };

      try {
        const response = await fetch(
          "https://quizapi.brainbuzzquiz.com/api/user/sendEmail",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );

        if (response.ok) {
          trackConversion()
          alert("Enquiry Submitted Successfully!");
          setFormData({
            name: "",
            email: "",
            phone: "",
            skype: "",
            accountType: "",
            message: "",
          }); // Reset the form
        } else {
          alert("Error: Unable to process the request.");
        }
      } catch (error) {
        console.error("Error submitting the enquiry:", error);
        alert("An unexpected error occurred. Please try again later.");
      } finally {
        setLoading(false); // Stop loading
      }
    }
  };

  const trackConversion = () => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-11537881027/rmhfCPW4ua8aEMOv2P0q'
      });
      console.log('Conversion event sent');
    } else {
      console.error('Google Tag Manager not loaded');
    }
  };

  return (
    <div id="contact" className="py-16 bg-gradient-to-b from-gray-100 to-white px-5">
      <h2 className="text-4xl font-bold text-center mb-10 text-gray-800">Contact Us</h2>
      <form
        className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-8 space-y-6"
        onSubmit={handleSubmit}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col">
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              className={`p-3 border rounded-lg ${
                errors.name ? "border-red-500" : "border-gray-300"
              }`}
              onChange={handleChange}
            />
            {errors.name && <span className="text-red-500 text-sm mt-1">{errors.name}</span>}
          </div>
          <div className="flex flex-col">
            <select
              name="accountType"
              value={formData.accountType}
              className={`p-3 border rounded-lg ${
                errors.accountType ? "border-red-500" : "border-gray-300"
              }`}
              onChange={handleChange}
            >
              <option value="">Select Account Type</option>
              <option value="advertiser">Advertiser</option>
              <option value="publisher">Publisher</option>
            </select>
            {errors.accountType && (
              <span className="text-red-500 text-sm mt-1">{errors.accountType}</span>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col">
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              className={`p-3 border rounded-lg ${
                errors.email ? "border-red-500" : "border-gray-300"
              }`}
              onChange={handleChange}
            />
            {errors.email && <span className="text-red-500 text-sm mt-1">{errors.email}</span>}
          </div>
          <div className="flex flex-col">
            <input
              type="text"
              name="phone"
              placeholder="Your Phone (10-digit)"
              value={formData.phone}
              className={`p-3 border rounded-lg ${
                errors.phone ? "border-red-500" : "border-gray-300"
              }`}
              onChange={handleChange}
            />
            {errors.phone && <span className="text-red-500 text-sm mt-1">{errors.phone}</span>}
          </div>
        </div>

        <div className="flex flex-col">
          <input
            type="text"
            name="skype"
            placeholder="Your Skype ID"
            value={formData.skype}
            className={`p-3 border rounded-lg ${
              errors.skype ? "border-red-500" : "border-gray-300"
            }`}
            onChange={handleChange}
          />
          {errors.skype && <span className="text-red-500 text-sm mt-1">{errors.skype}</span>}
        </div>

        <div className="flex flex-col">
          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            className={`p-3 border rounded-lg ${
              errors.message ? "border-red-500" : "border-gray-300"
            }`}
            rows="5"
            onChange={handleChange}
          ></textarea>
          {errors.message && <span className="text-red-500 text-sm mt-1">{errors.message}</span>}
        </div>

        <button
          type="submit"
          className={`w-full py-3 rounded-lg text-white ${
            loading ? "bg-gray-400 cursor-not-allowed" : "bg-purple-600 hover:bg-purple-700"
          } transition`}
          disabled={loading}
        >
          {loading ? "Sending..." : "Send Message"}
        </button>
      </form>
    </div>
  );
};

export default Contact;
