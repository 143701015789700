import React from "react";

const Footer = () => {
  return (
    <footer className="bg-indigo-600 text-white py-8">
      <div className="max-w-6xl mx-auto px-5 flex flex-col md:flex-row justify-between items-center">
        <p className="text-sm">
          © {new Date().getFullYear()} ClickByte Media. All rights reserved.
        </p>
        <div className="flex space-x-4 mt-4 md:mt-0">
          <a href="#features" className="hover:text-gray-200">
            Features
          </a>
          <a href="#steps" className="hover:text-gray-200">
            Steps
          </a>
          <a href="#contact" className="hover:text-gray-200">
            Contact
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
