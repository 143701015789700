import React from "react";
import { motion } from "framer-motion";

const AdFormats = () => {
  const formats = [
    {
      title: "Banner Ads",
      description:
        "Banner ads for apps are a type of in-app advertising format that consists of small, rectangular ads displayed within an app's interface. They are typically positioned at the top or bottom of the screen, remaining visible as users interact with the app. This format is widely used due to its simplicity, non-intrusive nature, and ability to deliver consistent impressions.",
    },
    {
      title: "Interstitial Ads",
      description:
        "Interstitial ads are full-screen advertisements that appear at natural transition points in an app, such as between levels or during pauses, and typically cover the entire screen, requiring user interaction to dismiss.",
    },
    {
      title: "App Open Ads",
      description:
        "App open ads are full-screen advertisements that appear when a mobile app is launched, displayed before the app's content, and typically designed to enhance user engagement without disrupting the initial app experience.",
    },
    {
      title: "Rewarded Ads",
      description:
        "Rewarded ads are advertisements that offer users in-app rewards, such as extra lives or virtual currency, in exchange for watching the ad voluntarily.",
    },
  ];

  return (
    <motion.div
      id="ad-formats"
      className="py-16 bg-gray-100"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <h2 className="text-4xl font-bold text-center mb-10">Ad Formats</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 px-5">
        {formats.map((format, index) => (
          <motion.div
            key={index}
            className="bg-gradient-to-r from-blue-500 to-green-500 text-white shadow-lg p-6 rounded-lg"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.2 }}
          >
            <h3 className="text-xl font-bold mb-4">{format.title}</h3>
            <p>{format.description}</p>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default AdFormats;
